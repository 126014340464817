import React, { Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import { GuardianRouter } from "../../modules";
import routes, { RouteSettings } from "../routes.config";
import ContextProviders from "./providers";
import '../../assets/scss/sb-admin-2.scss';
import LoadingModal from '../../components/Common/LoadingModal';

function Root() {

  return (
    <ContextProviders>
      <Switch> 
        {routes.map(
          ({ component: Content, key, routeProps, contentProps }) => (
            <Route
              key={key}
              {...routeProps}
              render={props => (
                <GuardianRouter {...props} settings={RouteSettings}>
                  {childProps => (
                    <Suspense fallback={<LoadingModal show={true} />}>
                      <Content {...contentProps} {...childProps} />
                    </Suspense>
                  )}
                </GuardianRouter>
              )}
            />
          )
        )}
      </Switch>
    </ContextProviders>
  );
}

export default Root;
