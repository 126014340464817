import React from "react";
import ReactDOM from "react-dom";
import { Root } from "./containers";
import * as serviceWorker from "./serviceWorker";
import './assets/scss/sb-admin-2.scss';
import './app.scss';
import { BrowserRouter } from "react-router-dom";

ReactDOM.render(
  <BrowserRouter>
    <Root />
  </BrowserRouter>,
  document.getElementById("wrapper"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();