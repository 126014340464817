import { lazy } from "react";
import map from "./map.config";

export const RouteSettings = {
  authorization: {
    defaultRoute: map.Root,
    routes: [map.Root, map.MedicalStaffList],
  },
  unauthorization: {
    defaultRoute: [],
    routes: [],
  },
  authentication: {
    defaultRoute: map.Login,
    routes: [map.Login, map.Auth, map.NewsDetailApp, map.TermPolicy],
  },
};

export default [
  {
    key: "login",
    routeProps: {
      exact: true,
      path: map.Login,
    },
    component: lazy(() => import("./Login")),
  },
  {
    key: "auth",
    routeProps: {
      exact: true,
      path: map.Auth,
    },
    component: lazy(() => import("./Login/authozire")),
  },
  {
    key: "newsdetailapp",
    routeProps: {
      exact: true,
      path: map.NewsDetailApp,
    },
    component: lazy(() => import("./Corporation/News/App/NewsDetailApp")),
  },
  {
    key: "termpolicy",
    routeProps: {
      exact: true,
      path: map.TermPolicy,
    },
    component: lazy(() => import("./Dashboard/Policy")),
  },
  {
    key: "main",
    routeProps: {
      path: map.Root,
    },
    component: lazy(() => import("./Main/Main")),
    contentProps: {
      routes: [
        {
          key: "dashboard",
          routeProps: {
            exact: true,
            path: map.Root,
          },
          component: lazy(() => import("./DoctorExaminations/MedicalStaffList")),
        },
        // {
        //   key: "dashboard-2",
        //   routeProps: {
        //     exact: true,
        //     path: map.Dashboard,
        //   },
        //   component: lazy(() => import("./Dashboard")),
        // },
        // {
        //   key: "training-roadmap",
        //   routeProps: {
        //     exact: true,
        //     path: map.Roadmap,
        //   },
        //   component: lazy(() => import("./Training/roadmap")),
        // },
        // {
        //   key: "training-roadmap-details",
        //   routeProps: {
        //     exact: true,
        //     path: map.RoadmapDetails,
        //   },
        //   component: lazy(() => import("./Training/roadmapdetails")),
        // },
        // {
        //   key: "training-learning",
        //   routeProps: {
        //     exact: true,
        //     path: map.Learning,
        //   },
        //   component: lazy(() => import("./Training/Learning")),
        // },
        // {
        //   key: "training-instruction",
        //   routeProps: {
        //     exact: true,
        //     path: map.Instruction,
        //   },
        //   component: lazy(() => import("./Training/Instruction")),
        // },
        // {
        //   key: "training-kpi",
        //   routeProps: {
        //     exact: true,
        //     path: map.TraniningKPI,
        //   },
        //   component: lazy(() => import("./Training/TrainingKPI")),
        // },
        // {
        //   key: "personal-info",
        //   routeProps: {
        //     exact: true,
        //     path: map.PersonalInfo
        //   },
        //   component: lazy(() => import("./PersonalInfo"))
        // },
        // {
        //   key: "edit-personal-info",
        //   routeProps: {
        //     exact: true,
        //     path: map.EditPersonalInfo
        //   },
        //   component: lazy(() => import("./PersonalInfo/edit/PersonalInfoEdit"))
        // },
        // {
        //   key: "position-recruiting",
        //   routeProps: {
        //     exact: true,
        //     path: map.PositionRecruiting
        //   },
        //   component: lazy(() => import("./InternalRecruitment/Jobs"))
        // },
        // {
        //   key: "position-applied",
        //   routeProps: {
        //     exact: true,
        //     path: map.PositionApplied
        //   },
        //   component: lazy(() => import("./InternalRecruitment/history/PositionApplied"))
        // },
        // {
        //   key: "position-introduced",
        //   routeProps: {
        //     exact: true,
        //     path: map.PositionIntroduced
        //   },
        //   component: lazy(() => import("./InternalRecruitment/history/IntroductedPositionList"))
        // },
        // {
        //   key: "position-recruiting-detail",
        //   routeProps: {
        //     exact: true,
        //     path: map.PositionRecruitingDetail
        //   },
        //   component: lazy(() => import("./InternalRecruitment/PositionRecruitingDetail"))
        // },
        // {
        //   key: "task",
        //   routeProps: {
        //     exact: true,
        //     path: map.Task
        //   },
        //   component: lazy(() => import("./Task/"))
        // },
        // {
        //   key: "request-task",
        //   routeProps: {
        //     exact: true,
        //     path: map.RequestTask
        //   },
        //   component: lazy(() => import("./Task/Request"))
        // },
        // {
        //   key: "request-task-edit",
        //   routeProps: {
        //     exact: true,
        //     path: map.RequestTaskEdit
        //   },
        //   component: lazy(() => import("./Task/TaskEdit"))
        // },
        // {
        //   key: "request-task-detail",
        //   routeProps: {
        //     exact: true,
        //     path: map.RequestTaskDetail
        //   },
        //   component: lazy(() => import("./Task/RequestDetail"))
        // },
        // {
        //   key: "approval-task",
        //   routeProps: {
        //     exact: true,
        //     path: map.ApprovalTask
        //   },
        //   component: lazy(() => import("./Task/Approval"))
        // },
        // {
        //   key: "approval-task-detail",
        //   routeProps: {
        //     exact: true,
        //     path: map.ApprovalTaskDetail
        //   },
        //   component: lazy(() => import("./Task/ApprovalDetail"))
        // },
        // ----------------------------------
        {
          key: "medical-staff-list",
          routeProps: {
            exact: true,
            path: map.MedicalStaffList
          },
          component: lazy(() => import("./DoctorExaminations/MedicalStaffList"))
        },
        {
          key: "vice-manager-synthetic",
          routeProps: {
            exact: true,
            path: map.ViceManagerSynthetic
          },
          component: lazy(() => import("./DoctorExaminations/ViceManagerSynthetic"))
        },
        {
          key: "expert",
          routeProps: {
            exact: true,
            path: map.Expert
          },
          component: lazy(() => import("./DoctorExaminations/Expert"))
        },
        {
          key: "general-assessment-council",
          routeProps: {
            exact: true,
            path: map.GeneralAssessmentCouncil
          },
          component: lazy(() => import("./DoctorExaminations/GeneralAssessmentCouncil"))
        },
        {
          key: "examination-council",
          routeProps: {
            exact: true,
            path: map.ExaminationCouncil
          },
          component: lazy(() => import("./DoctorExaminations/ExaminationCouncil"))
        },
        {
          key: "profile-medical",
          routeProps: {
            exact: true,
            path: map.ProfileDoctor
          },
          component: lazy(() => import("./DoctorExaminations/DoctorSupplement"))
        },
        {
          key: "competency-frame",
          routeProps: {
            exact: true,
            path: map.CompetencyFrame
          },
          component: lazy(() => import("./DoctorExaminations/CompetencyFrame"))
        },
        {
          key: "competency-frame-detail",
          routeProps: {
            exact: true,
            path: map.CompetencyFrameDetail
          },
          component: lazy(() => import("./DoctorExaminations/CompetencyFrameDetail"))
        },
        {
          key: "OH-category",
          routeProps: {
            exact: true,
            path: map.OHCategory
          },
          component: lazy(() => import("./DoctorExaminations/OHCategory"))
        },
        {
          key: "examination-council-detail",
          routeProps: {
            exact: true,
            path: map.ExaminationCouncilDetail
          },
          component: lazy(() => import("./DoctorExaminations/ExaminationCouncilDetail"))
        },
        {
          key: "examination-config-doctor",
          routeProps: {
            exact: true,
            path: map.ExaminationConfigDoctor
          },
          component: lazy(() => import("./DoctorExaminations/ExaminationConfig"))
        },
        {
          key: "examination-config-nurse",
          routeProps: {
            exact: true,
            path: map.ExaminationConfigNurse
          },
          component: lazy(() => import("./DoctorExaminations/ExaminationConfig"))
        },
        {
          key: "resident-doctor-list",
          routeProps: {
            exact: true,
            path: map.ResidentDoctorList
          },
          component: lazy(() => import("./ResidentDoctor/ResidentDoctorList"))
        },
        {
          key: "resident-doctor-detail",
          routeProps: {
            exact: true,
            path: map.ResidentDoctorDetail
          },
          component: lazy(() => import("./ResidentDoctor/ResidentDoctorDetail"))
        },
        {
          key: "self-traning-plan",
          routeProps: {
            exact: true,
            path: map.TrainingPlan
          },
          component: lazy(() => import("./ResidentDoctor/ResidentDoctorDetail"))
        },
        // Report 
        {
          key: "task-training-report",
          routeProps: {
            exact: true,
            path: map.TrainingReport
          },
          component: lazy(() => import("./ResidentDoctor/TrainingReport"))
        },
        {
          key: "doctor-training-report",
          routeProps: {
            exact: true,
            path: map.TrainingReportInfo
        },
          component: lazy(() => import("./ResidentDoctor/TrainingReportDetail"))
        },
        {
          key: "self-training-report",
          routeProps: {
            exact: true,
            path: map.TrainingReportDetail
          },
          component: lazy(() => import("./ResidentDoctor/TrainingReportDetail"))
        },
        {
          key: "manage-role-vinmec",
          routeProps: {
            exact: true,
            path: map.ManageRoleVinmec
          },
          component: lazy(() => import("./AdministratorVinmec/ManageRoleVinmec"))
        },
        {
          key: "manage-language-level",
          routeProps: {
            exact: true,
            path: map.ManageLanguageLevel
          },
          component: lazy(() => import("./AdministratorVinmec/ManageLanguageLevel"))
        },
        // ----------------------------------
        // {
        //   key: "timesheet",
        //   routeProps: {
        //     exact: true,
        //     path: map.Timesheet
        //   },
        //   component: lazy(() => import("./Timesheet"))
        // },
        // {
        //   key: "leaveTime",
        //   routeProps: {
        //     exact: true,
        //     path: map.LeaveTime
        //   },
        //   component: lazy(() => import("./LeaveTime"))
        // },
        // {
        //   key: "payslips",
        //   routeProps: {
        //     exact: true,
        //     path: map.PaySlips
        //   },
        //   component: lazy(() => import("./PaySlips/"))
        // },
        // {
        //   key: "working-process",
        //   routeProps: {
        //     exact: true,
        //     path: map.WorkingProcess
        //   },
        //   component: lazy(() => import("./PersonalInfo/workingprocess"))
        // },
        // {
        //   key: "job",
        //   routeProps: {
        //     exact: true,
        //     path: map.Job,
        //   },
        //   component: lazy(() => import("./job")),
        // },
        // {
        //   key: "job-upload",
        //   routeProps: {
        //     exact: true,
        //     path: map.JobUpload,
        //   },
        //   component: lazy(() => import("./job/upload")),
        // },
        {
          key: "news",
          routeProps: {
            exact: true,
            path: map.News,
          },
          component: lazy(() => import("./Corporation/News/ListNews")),
        },
        {
          key: "newsdetail",
          routeProps: {
            exact: true,
            path: map.NewsDetail,
          },
          component: lazy(() => import("./Corporation/News/NewsDetail")),
        },
        {
          key: "about-vingroup",
          routeProps: {
            exact: true,
            path: map.Vingroup,
          },
          component: lazy(() => import("./Corporation/Vingroup")),
        },
        {
          key: "about-vinpearl",
          routeProps: {
            exact: true,
            path: map.Vinpearl,
          },
          component: lazy(() => import("./Corporation/Vinpearl")),
        },
        {
          key: "about-vinfast",
          routeProps: {
            exact: true,
            path: map.Vinfast,
          },
          component: lazy(() => import("./Corporation/Vinfast")),
        },
        {
          key: "about-vinmec",
          routeProps: {
            exact: true,
            path: map.Vinmec,
          },
          component: lazy(() => import("./Corporation/Vinmec")),
        },
        {
          key: "notifications",
          routeProps: {
            exact: true,
            path: map.Notifications
          },
          component: lazy(() => import("./Notifications/index"))
        },
        {
          key: "notifications-unread",
          routeProps: {
            exact: true,
            path: map.NotificationsUnRead
          },
          component: lazy(() => import("./Notifications/ListNotificationsUnRead/ListNotificationsUnRead"))
        }, 
        {
          key: "notification-detail",
          routeProps: {
            exact: true,
            path: map.NotificationDetail
          },
          component: lazy(() => import("./Notifications/Detail/Detail"))
        },
        // {
        //   key: "benefit",
        //   routeProps: {
        //     exact: true,
        //     path: map.Benefit
        //   },
        //   component: lazy(() => import("./Benefit"))
        // },        
        // {
        //   key: "kpi",
        //   routeProps: {
        //     exact: true,
        //     path: map.Kpi
        //   },
        //   component: lazy(() => import("./KPI"))
        // },        
        // {
        //   key: "kpi-detail",
        //   routeProps: {
        //     exact: true,
        //     path: map.KpiDetail
        //   },
        //   component: lazy(() => import("./KPI/KPIDetail"))
        // },
        {
          key: "forbidden",
          routeProps: {
            exact: true,
            path: "/access-denied",
          },
          component: lazy(() => import("./AccessDenied")),
        },
        {
          key: "not-found",
          routeProps: {
            exact: true,
            path: "/not-found",
          },
          component: lazy(() => import("./NotFound")),
        },
        {
          key: "registration",
          routeProps: {
            exact: true,
            path: map.Registration
          },
          component: lazy(() => import("./Registration"))
        },
        {
          key: "registration-detail-request",
          routeProps: {
            exact: true,
            path: map.RegistrationDetailRequest
          },
          component: lazy(() => import("./Registration/RegistrationDetailComponent"))
        },
        {
          key: "registration-detail-approval",
          routeProps: {
            exact: true,
            path: map.RegistrationDetailApproval
          },
          component: lazy(() => import("./Registration/RegistrationDetailComponent"))
        },
        {
          key: "registration-detail-consent",
          routeProps: {
            exact: true,
            path: map.RegistrationDetailConsent
          },
          component: lazy(() => import("./Registration/RegistrationDetailComponent"))
        },
        {
          key: "edit-registration",
          routeProps: {
            exact: true,
            path: map.RegistrationEdit
          },
          component: lazy(() => import("./Registration/RegistrationEditComponent"))
        },
        {
          key: "question-and-answer",
          routeProps: {
            exact: true,
            path: map.QuestionAndAnswer
          },
          component: lazy(() => import("./QuestionAndAnswer"))
        },
        {
          key: "question-and-answer-details",
          routeProps: {
            exact: true,
            path: map.QuestionAndAnswerDetails
          },
          component: lazy(() => import("./QuestionAndAnswer/QuestionAndAnswerDetails"))
        },
        {
          key: "manage-training-plan",
          routeProps: {
            exact: true,
            path: map.ManageTrainingPlan
          },
          component: lazy(() => import("./ResidentDoctor/ManageTrainingPlan"))
        }
      ],
    },
  },
];
