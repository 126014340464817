import map from "../../containers/map.config";

export const Navigation = [
  // {
  //   id: 1,
  //   parentId: 0,
  //   icon: "c-home",
  //   label: "Menu",
  //   to: "/",
  //   role: "U",
  // },
  // {
  //   id: 10,
  //   parentId: 0,
  //   icon: "c-hr",
  //   label: "Menu_HumanResource",
  //   role: "U",
  // },
  // {
  //   id: 2,
  //   parentId: 0,
  //   icon: "c-dt",
  //   label: "Menu_Training",
  //   role: "U",
  // },
  // {
  //   id: 11,
  //   parentId: 0,
  //   icon: "c-recruiment",
  //   label: "Menu_InternalRecruitment",
  //   to: map.InternalRecruitment,
  //   role: ["V030"]
  // },
  // ----------------------------------------
  // {
  //   id: 12,
  //   parentId: 0,
  //   icon: "c-task",
  //   label: "Menu_Task",
  //   role: "U"
  // },

  {
    id: 48,
    parentId: 0,
    icon: "c-examination",
    label: "Khảo thí nhân viên y tế",
    to: map.MedicalStaffList,
    role: "U"
  },
  {
    id: 39,
    parentId: 0,
    icon: "c-resident",
    label: "Quản lý bác sĩ nội trú",
    to: map.ResidentDoctorList,
    role: ["5", "10", "11", "12"]
  },
  {
    id: 46,
    parentId: 0,
    icon: "c-managerole",
    label: "Quản lý phân quyền",
    to: map.ManageRoleVinmec,
    role: ["5", "8", "14"]
  },
  {
    id: 31,
    parentId: 48,
    icon: "menu-bullet-lv2 icon-sub-menu-lv2",
    label: "Theo dõi NLCM và năng lực ĐT & NCKH",
    to: map.MedicalStaffList,
    role: ["1","2", "11", "3", "4", "5","6", "8", "13", "14", "15"]
  },
  {
    id: 33,
    parentId: 48,
    icon: "menu-bullet-lv2 icon-sub-menu-lv2",
    label: "Tổng hợp kết quả khảo thí",
    to: map.ViceManagerSynthetic,
    role: ["12"]
  },
  {
    id: 34,
    parentId: 48,
    icon: "menu-bullet-lv2 icon-sub-menu-lv2",
    label: "Tạo HĐĐG chung",
    to: map.GeneralAssessmentCouncil,
    role: ["5", "14"]
  },
  {
    id: 35,
    parentId: 48,
    icon: "menu-bullet-lv2 icon-sub-menu-lv2",
    label: "Tạo HĐĐG chuyên ngành",
    to: map.ExaminationCouncil,
    role: ["5", "14"]
  },
  {
    id: 36,
    parentId: 48,
    icon: "menu-bullet-lv2 icon-sub-menu-lv2",
    label: "Danh sách chuyên gia",
    to: map.Expert,
    role: ["5", "14"]
  },
  {
    id: 37,
    parentId: 48,
    icon: "menu-bullet-lv2 icon-sub-menu-lv2",
    label: "Quản lý Khung năng lực và DMKT",
    to: map.CompetencyFrame,
    role: ["5", "14"]
  },
  {
    id: 38,
    parentId: 48,
    icon: "menu-bullet-lv2 icon-sub-menu-lv2",
    label: "Quản lý DMKT OH",
    to: map.OHCategory,
    role: ["5", "14"]
  },
  {
    id: 40,
    parentId: 39,
    icon: "menu-bullet-lv2 icon-sub-menu-lv2",
    label: "Danh sách kế hoạch đào tạo",
    to: map.ResidentDoctorList,
    role: ["5"]
  },
  {
    id: 41,
    parentId: 39,
    icon: "menu-bullet-lv2 icon-sub-menu-lv2",
    label: "Quản lý kế hoạch đào tạo",
    to: map.ManageTrainingPlan,
    role: ["5"]
  },
  {
    id: 43,
    parentId: 39,
    icon: "menu-bullet-lv2 icon-sub-menu-lv2",
    label: "Kế hoạch đào tạo",
    to: map.TrainingPlan,
    role: ["10"]
  },
  {
    id: 47,
    parentId: 39,
    icon: "menu-bullet-lv2 icon-sub-menu-lv2",
    label: "Kế hoạch đào tạo",
    to: map.ResidentDoctorList,
    role: ["11","12"]
  },
  {
    id: 44,
    parentId: 39,
    icon: "menu-bullet-lv2 icon-sub-menu-lv2",
    label: "Báo cáo kết quả đào tạo",
    to: map.TrainingReportDetail,
    role: ["10"]
  },
  {
    id: 45,
    parentId: 39,
    icon: "menu-bullet-lv2 icon-sub-menu-lv2",
    label: "Báo cáo kết quả đào tạo",
    to: map.TrainingReport,
    role: ["5", "11", "12"]
  },
  {
    id: 49,
    parentId: 48,
    icon: "menu-bullet-lv2 icon-sub-menu-lv2",
    label: "Quản lý bậc ngoại ngữ",
    to: map.ManageLanguageLevel,
    role: ["8"]
  },
  {
    id: 50,
    parentId: 48,
    icon: "menu-bullet-lv2 icon-sub-menu-lv2",
    label: "Cấu hình khảo thí bác sĩ",
    to: map.ExaminationConfigDoctor,
    role: ["8","5"]
  },
  {
    id: 51,
    parentId: 48,
    icon: "menu-bullet-lv2 icon-sub-menu-lv2",
    label: "Cấu hình khảo thí điều dưỡng",
    to: map.ExaminationConfigNurse,
    role: ["8","14"]
  },
  // --------------------------------------------------------
  {
    id: 3,
    parentId: 2,
    icon: "menu-bullet-lv2 icon-sub-menu-lv2",
    label: "Menu_Learning",
    to: map.Learning,
    role: "U",
  },
 
  {
    id: 4,
    parentId: 2,
    icon: "menu-bullet-lv2 icon-sub-menu-lv2",
    label: "Menu_Intruction",
    to: map.Instruction,
    role: ["P","T","P2", "P1", "T4", "T3", "T2", "T1"],
  },
  {
    id: 5,
    parentId: 2,
    icon: "menu-bullet-lv2 icon-sub-menu-lv2",
    label: "Menu_TrainingRoadmap",
    to: map.Roadmap,
    role: ["P","T","P2", "P1", "T4", "T3", "T2", "T1"],
  },
  {
    id: 7,
    parentId: 2,
    icon: "menu-bullet-lv2 icon-sub-menu-lv2",
    label: "Menu_TrainingKPI",
    to: map.TraniningKPI,
    role: "U",
  },
  // {
  //   id: 13,
  //   parentId: 0,
  //   icon: "c-group",
  //   label: "Menu_GroupInformation",
  //   to: "/announcement",
  //   role: "U",
  // },
  // {
  //   id: 14,
  //   parentId: 13,
  //   icon: "menu-bullet-lv2 icon-sub-menu-lv2",
  //   label: "Menu_GroupIntroduction",
  //   to: map.Vingroup,
  //   role: "U"
  // },
  // {
  //   id: 101,
  //   parentId: 13,
  //   icon: "menu-bullet-lv2 icon-sub-menu-lv2",
  //   label: "Menu_VinpearlIntroduction",
  //   to: map.Vinpearl,
  //   role: ["V030"]
  // },
  // {
  //   id: 102,
  //   parentId: 13,
  //   icon: "menu-bullet-lv2 icon-sub-menu-lv2",
  //   label: "Menu_VinfastIntroduction",
  //   to: map.Vinfast,
  //   role: ["V070","V077"]
  // },
  // {
  //   id: 103,
  //   parentId: 13,
  //   icon: "menu-bullet-lv2 icon-sub-menu-lv2",
  //   label: "Menu_VinmecIntroduction",
  //   to: map.Vinmec,
  //   role: ["V060"]
  // },
  // {
  //   id: 16,
  //   parentId: 13,
  //   icon: "menu-bullet-lv2 icon-sub-menu-lv2",
  //   label: "Menu_News",
  //   to: map.News,
  //   role: "U",
  // },
  {
    id: 17,
    parentId: 10,
    icon: "menu-bullet-lv2 icon-sub-menu-lv2",
    label: "PersonalInformation",
    to: map.PersonalInfo,
    role: "U"
  },
  {
    id: 18,
    parentId: 10,
    icon: "menu-bullet-lv2 icon-sub-menu-lv2",
    label: "MyJob",
    to: map.Job,
    role: "U"
  },
  {
    id: 30,
    parentId: 17,
    icon: "menu-bullet-lv2 icon-sub-menu-lv2",
    label: "BasicInformation",
    to: map.PersonalInfo,
    role: "U"
  },
  {
    id: 32,
    parentId: 17,
    icon: "menu-bullet-lv2 icon-sub-menu-lv2",
    label: "WorkingProcess",
    to: map.WorkingProcess,
    role: "U"
  },
  {
    id: 24,
    parentId: 11,
    icon: "menu-bullet-lv2 icon-sub-menu-lv2",
    label: "Menu_PositionRecruiting",
    to: map.PositionRecruiting,
    role: "U"
  },
  {
    id: 25,
    parentId: 11,
    icon: "menu-bullet-lv2 icon-sub-menu-lv2",
    label: "Menu_PositionApplied",
    to: map.PositionApplied,
    role: "U"
  },
  {
    id: 26,
    parentId: 11,
    icon: "menu-bullet-lv2 icon-sub-menu-lv2",
    label: "ReferredPositions",
    to: map.PositionIntroduced,
    role: "U"
  },
  // {
  //   id: 99,
  //   parentId: 10,
  //   icon: "menu-bullet-lv2 icon-sub-menu-lv2",
  //   label: "Phúc lợi",
  //   to: map.Benefit,
  //   role: ["V030"]
  // },
  {
    id: 19,
    parentId: 18,
    icon: "menu-bullet-lv2 icon-sub-menu-lv2",
    label: "JobDescription",
    to: map.Job,
    role: ["V030","V096","V073","V060"]
  },
  // {
  //   id: 20,
  //   parentId: 0,
  //   icon: "c-notification",
  //   label: "Menu_Notification",
  //   to: map.Notifications,
  //   role: "U"
  // },
  {
    id: 21,
    parentId: 18,
    icon: "menu-bullet-lv2 icon-sub-menu-lv2",
    label: "Menu_KPI",
    to: map.Kpi,
    role: "U"
  },
  {
    id: 22,
    parentId: 10,
    icon: "menu-bullet-lv2 icon-sub-menu-lv2",
    label: "Menu_Timesheet",
    to: map.Timesheet,
    role: "U"
  },
  {
    id: 23,
    parentId: 10,
    icon: "menu-bullet-lv2 icon-sub-menu-lv2",
    label: "Menu_LeaveTime",
    to: map.LeaveTime,
    role: "U"
  },
  {
    id: 27,
    parentId: 10,
    icon: "menu-bullet-lv2 icon-sub-menu-lv2",
    label: "Menu_PaySlip",
    to: map.PaySlips,
    role: "U"
  },
  {
    id: 97,
    parentId: 10,
    icon: "menu-bullet-lv2 icon-sub-menu-lv2",
    label: "Registration",
    label2: "Registration",
    to: map.Registration,
    role: "U"
  },
  {
    id: 98,
    parentId: 0,
    icon: "c-qa",
    label: "QuestionAndAnswer",
    to: map.QuestionAndAnswer,
    role: ["V030","V096","V060","V073"]
  },
];
